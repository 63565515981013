import FormContainer from 'app/components/Containers/FormContainer';
import CategoryAttributeForm from 'app/components/Forms/Categories';
import CategorieContext from 'app/contexts/CategorieContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import {
  CategoryAttributeRelations,
  IDataCategoryAttribute,
} from 'app/types/data/IDataCategoryAttribute';
import { IDataPartner } from 'app/types/data/IDataPartner';
import { IDataPartnerCategory } from 'app/types/data/IDataPartnerCategory';
import DisplayAttributes from 'app/views/curated/attribute/components/DisplayAttributes';
import React from 'react';
import useAuth from 'app/hooks/useAuth';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import { useLocation } from 'react-router-dom';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';

declare interface ICategoryAttributeFormContainer {
  data?: IDataCategoryAttribute | IDataPartnerCategory;
  selected?: IDataCategoryAttribute | IDataPartnerCategory;
  relation?: IDataRelation<CategoryAttributeRelations>;
  partner?: IDataPartner;
  onClickAttach?: () => void;
  onSubmit: (data?) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const CategoryAttributeFormContainer = (props: ICategoryAttributeFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()

  const [data, setData] = React.useState<IDataCategoryAttribute | IDataPartnerCategory | undefined>(
    props.data
  );
  const [selected, setSelected] = React.useState<
    IDataCategoryAttribute | IDataPartnerCategory | undefined
  >(props.data);
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('0')
  function getMenuSequency(sequency?: any, menus?: any[]) {
    return menus?.find(x => x.sequency === sequency)
  }

  async function getItemsMenu() {
    try {

      let tabMenu: any = [
        {
          tag: 'atributo',
          title: 'Atributos',
          component: (
            <DisplayAttributes
              relation={{ id: props.data!.id as number, relation: 'Category' }}
              view="grid"
              local="categoria"

            />
          ),
        }
      ]

      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }

      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'CATEGORIAS')) {

          const filho = funcFilhos.find(f => f.nome === 'CATEGORIAS')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("CATEGORIAS", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        }
        else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("CATEGORIAS", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      }

      if (res && res.length > 0) {
        res = res?.filter((item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => (
            t.funcionalidade_id === item.funcionalidade_id
          ))
        )
        let nItems = res.map((itemRes: any) => {
          return {
            ...itemRes,
            campos: itemRes?.campos?.map(campo => {
              return {
                ...campo,
                nome: campo.funcionalidade_campo.nome,
              }
            })
          }
        })

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];

        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        tabMenu = [
          {
            tag: 'atributo',
            title: 'Atributos',
            component: (
              <DisplayAttributes
                relation={{ id: props.data!.id as number, relation: 'Category' }}
                view="grid"
                local="categoria"
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.campos}
                sequency={getMenuSequency(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.sequency, nItems)}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.sequency].nome)
                }}
              />
            ),
          }
        ]
        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data);
    setSelected(props.selected);
    getItemsMenu()
  }, [props.data, props.selected]);

  return (
    <CategorieContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <CategoryAttributeForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            partner={props.partner}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          data &&
          selected && tabsMenu
        }
      />
    </CategorieContext.Provider>
  );
};

export default CategoryAttributeFormContainer;

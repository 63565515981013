import React from 'react';
import { useLocation } from 'react-router-dom';

import FormContainer from 'app/components/Containers/FormContainer';
import FormUser from 'app/components/Forms/User';
import { IDataUser, UserRelations } from 'app/types/data/IDataUser';
import DisplayGroupUser from 'app/views/management/user-group/components/DisplayGroupUser';
import { IDataRelation } from 'app/types/data/IData';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
declare interface IUserFormContainer {
  data?: IDataUser;
  relation?: IDataRelation<UserRelations>;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
}

const UserFormContainer = (props: IUserFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('0')
  const [data, setData] = React.useState<IDataUser | undefined>(props.data);

  async function getItemsMenu() {
    try {
      let tabMenu: any = [
        {
          title: 'Grupos',
          component: <DisplayGroupUser relation={{ id: props.data!.id as number, relation: 'User' }} />,
        },
      ]

      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'USUARIOS')) {

          const filho = funcFilhos.find(f => f.nome === 'USUARIOS')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("USUARIOS", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("USUARIOS", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      }
      if (res && res.length > 0) {
        res = res?.filter((item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => (
            t.funcionalidade_id === item.funcionalidade_id
          ))
        )
        let nItems = res.map((itemRes: any) => {
          return {
            ...itemRes,
            campos: itemRes?.campos?.map(campo => {
              return {
                ...campo,
                nome: campo.funcionalidade_campo.nome,
              }
            })
          }
        })

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];

        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Grupos',
            component: <DisplayGroupUser relation={{ id: props.data!.id as number, relation: 'User' }}
              sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'User')?.sequency}
              onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'User')?.sequency)}
            />,
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  return (
    <FormContainer
      mainForm={
        <FormUser
          setSelected={(data) => props.setSelected(data)}
          onChangeRelation={(selectedData) => setData(selectedData)}
          relation={props.relation}
          data={props.data}
          type="update"
          onSubmit={props.onSubmit}
          onCancel={props.cancel}
        />
      }
      tabs={
        data && tabsMenu
      }
    />
  );
};

export default UserFormContainer;

import { MultiBackend, NodeModel, Tree, getBackendOptions } from '@minoru/react-dnd-treeview';
import { CircularProgress, CssBaseline, Paper, ThemeProvider, styled } from '@mui/material';
import Attributes_Api from 'app/api/Attributes_Api';
import CategoriesAttribute_Api from 'app/api/CategoriesAttribute_Api';
import { IDataCategoryAttribute } from 'app/types/data/IDataCategoryAttribute';
import { IDataPartnerCategory } from 'app/types/data/IDataPartnerCategory';
import React from 'react';
import { DndProvider } from 'react-dnd';
import styles from './App.module.css';
import { CustomDragPreview } from './components/CustomDragPreview';
import { CustomNode } from './components/CustomNode';
import { Placeholder } from './components/Placeholder';
import { theme } from './components/theme';

const ContentBox = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  opacity: 1,
  zIndex: 999,
}));

interface ITreeViewCategoriesAttribute {
  data: NodeModel<IDataCategoryAttribute | IDataPartnerCategory>[];
  nodeSelected?: NodeModel<IDataCategoryAttribute | IDataPartnerCategory>;
  loading?: boolean;
  clearSelected?: boolean;
  onDrop?: () => void;
  onUpdate?: (
    node: NodeModel<IDataCategoryAttribute | IDataPartnerCategory>,
    newValue: string
  ) => void;
  onClickNode: (node?: NodeModel<IDataCategoryAttribute | IDataPartnerCategory>) => void;
}

export default function TreeViewCategoriesAttribute(props: ITreeViewCategoriesAttribute) {
  const [treeData, setTreeData] = React.useState<
    NodeModel<IDataCategoryAttribute | IDataPartnerCategory>[]
  >(props.data);
  const [selectedNode, setSelectedNode] = React.useState<NodeModel<
    IDataCategoryAttribute | IDataPartnerCategory
  > | null>(null);

  const handleDrop = async (newTree: NodeModel<IDataCategoryAttribute>[], options) => {
    const { dragSourceId, dropTargetId, dragSource, dropTarget } = options;
    if (
      dragSource?.data?.is_attribute === dropTarget?.data?.is_attribute ||
      (!dropTarget && !dragSource?.data?.is_attribute) ||
      dropTargetId === dragSource.parent
    ) {
      try {
        const subTree = newTree.filter((node) => node.parent === dropTargetId);
        const newIndex = subTree.findIndex((node) => node.id === dragSourceId);
        if (dragSource?.data?.is_attribute) {
          await Attributes_Api.update({
            ...dragSource.data,
            classificacao: newIndex + 1,
            parent_id: dragSource?.data?.parent?.id || null,
          });
        } else {
          await CategoriesAttribute_Api.update({
            ...dragSource.data,
            classificacao: newIndex + 1,
            parent_id: dropTarget?.data?.id || null,
          });
        }
        setTreeData(newTree);
        if (props.onDrop) props.onDrop();
      } catch (error) { }
    }
  };

  React.useEffect(() => {
    if (props.nodeSelected) setSelectedNode(props.nodeSelected);
  }, [props.nodeSelected]);

  React.useEffect(() => {
    if (props.clearSelected) setSelectedNode(null);
  }, [props.clearSelected]);

  React.useEffect(() => {
    setTreeData(props.data);
  }, [props.data]);

  function handleSelect(node: NodeModel<IDataCategoryAttribute>) {
    if (selectedNode?.id === node.id) {
      setSelectedNode(null);
      props.onClickNode(undefined);
      return;
    }
    setSelectedNode(node);
    props.onClickNode(node);
  }
  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <div className={styles.app}>
            {props.loading && (
              <ContentBox>
                <CircularProgress color="primary" />
              </ContentBox>
            )}
            <Tree
              tree={treeData}
              rootId={0}
              render={(
                node: NodeModel<IDataCategoryAttribute | IDataPartnerCategory>,
                { depth, isOpen, onToggle }
              ) => (
                <CustomNode
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  onToggle={onToggle}
                  onSelect={handleSelect}
                  isSelected={node.id === selectedNode?.id}
                  onUpdate={(value) => (props.onUpdate ? props.onUpdate(node, value) : undefined)}
                />
              )}
              dragPreviewRender={(monitorProps) => (
                <CustomDragPreview monitorProps={monitorProps} />
              )}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                placeholder: styles.placeholderContainer,
              }}
              sort={false}
              insertDroppableFirst={false}
              canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                if (dragSource?.parent === dropTargetId) {
                  return true;
                }
              }}
              dropTargetOffset={10}
              placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
            />
          </div>
        </DndProvider>
      </ThemeProvider>
    </Paper>
  );
}

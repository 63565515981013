import { useLocation } from 'react-router-dom';
import Orientation_Api from 'app/api/Orientation_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import FormOrientation from 'app/components/Forms/Orientation';
import { IDataRelation } from 'app/types/data/IData';
import { IDataOrientation, OrientationRelations } from 'app/types/data/IDataOrientation';
import React from 'react';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
declare interface IOrientationFormContainer {
  data?: IDataOrientation;
  relation?: IDataRelation<OrientationRelations>;
  onClickAttach?: () => void;
  onSubmit: (item: any) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const OrientationFormContainer = (props: IOrientationFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('0')
  const [data, setData] = React.useState<IDataOrientation | undefined>(props.data);

  async function getItemsMenu() {
    try {
      let tabMenu: any = [
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={Orientation_Api}
              onClickAttach={props.onClickAttach}

            />
          ),
        },
      ]
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      const res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      if (res && res.length > 0) {
        let nItems = res.map(campoItem => {
          return {
            ...campoItem,
            campos: campoItem?.campos?.map(campo => {
              return {
                ...campo,
                nome: campo.funcionalidade_campo.nome,
              }
            })
          }
        })

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hidden = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                relationData={data}
                apiModel={Orientation_Api}
                onClickAttach={props.onClickAttach}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.campos}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency].nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency}

              />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  return (
    <>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <FormOrientation
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          data && tabsMenu
        }
      />
    </>
  );
};

export default OrientationFormContainer;

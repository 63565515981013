import { Button, Grid } from '@mui/material';
import Activity_Api from 'app/api/Activity_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
//import { useBreadcrumb } from 'app/contexts/BreadcrumbContext';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import WorkflowActivityContext from 'app/contexts/WorkflowActivityContext';
import WorkflowContext from 'app/contexts/WorkflowContext';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  ActivityRelations,
  IActivityFilter,
  IDataActivity,
  IDataActivityErrors,
} from 'app/types/data/IDataActivity';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import React, { Fragment, SetStateAction, useContext } from 'react';
import { CSVLink } from 'react-csv';
import ActivityFormContainer from '../ActivityFormContainer';
import TreeViewActivity from '../ActivityTreeView';
import CreateAttachment from '../CreateAttachment';
import SubActivityFormContainer from '../SubActivitys/SubActivityFormContainer';
import { getColumns, hiddenColumns } from './columns';
import CloneActivities from 'app/components/Toolbar/CustomIcons/CloneActivities/CloneActivities';
import { convertErroToString } from 'app/utils/formatErrors';
import { useToast } from 'app/hooks/useToast';
import handleOnSortModelChange from 'app/utils/handleOnSortModelChange';
import selectRow from 'app/utils/selectRow';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';

const header = getColumns().map((item) => item.headerName);

const UPDATE_MSG = getMessage('Atividade', 'update', 'a');
const DELETE_MSG = getMessage('Atividade', 'delete', 'a');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplayActivity {
  view: 'tree' | 'grid';
  type?: 'sucessoras' | 'predecessoras' | 'subatividades';
  relation?: IDataRelation<ActivityRelations>;
  displayName: string;
  campos?: IDataBpmCampo[]
  onSubmit?: (item?: IDataActivity) => void;
  sequency?: any;
  typeForm?: string | undefined
}

const DisplayActivity = (props: IDisplayActivity) => {
  const { handleResetForm } = useUtilsContext();
  const activityCtx = useContext(WorkflowActivityContext);
  const workflowCtx = useContext(WorkflowContext);
  const sequencyName = localStorage.getItem('sequencyName')
  const [isGridView, toggleView] = React.useState(!(sequencyName && sequencyName.toLowerCase().includes('sucessoras')));
  const [typeForm, setTypeForm] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [dataGrid, setDataGrid] = React.useState<IDataActivity[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [multipleSelected, setMultipleSelected] = React.useState<string[] | number[]>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [isCheckboxOn, setIsCheckboxOn] = React.useState<boolean>(false);
  const [wasSelectAllActivitiesClicked, setWasSelectAllActivitiesClicked] =
    React.useState<boolean>(false);
  const [isAllPageRowsSelected, selectAllPageRows] = React.useState<boolean | null>(false);

  const [selected, setSelected] = React.useState<IDataActivity | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [openAttach, toggleAttachModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [cloneActivities, setCloneActivities] =
    React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const csvLink = React.useRef<any | null>();
  //const { setBreadCrumb } = useBreadcrumb();

  const { showToast } = useToast();

  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.summary,
    displayName: props?.displayName || 'Atividades',
    toggleView,
  });

  const currentSelectedRowIndex = data?.findIndex((item) => item?.id === selected?.id);

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);

  React.useEffect(() => {
    if (sequencyName && sequencyName.toLowerCase().includes('sucessoras')) {
      toggleView(false);
      setSelected(undefined);
      setTypeForm('create')
    }

    return () => {
      const sequencyName = localStorage.getItem('sequencyName')
      if (sequencyName && sequencyName.toLowerCase().includes('sucessoras')) {
        localStorage.removeItem('sequencyName')
      }
    };
  }, []);

  async function load() {
    setLoading(true);
    try {
      let Activities: any;
      const parametros: IActivityFilter = {
        q: search,
        page: props.view === 'grid' ? page + 1 : 1,
        per_page: props.view === 'grid' ? pageSize : 9999,
        is_null: props.view === 'grid' && !activityCtx ? 'parent_id' : undefined,
        ...(props.view === 'tree' ? { sort: 'order', order: 'asc' } : {}),
        ...params,
      };
      let items: any;
      if (props.type === 'sucessoras') {
        items = await Activity_Api.sucessoras(parametros, props.relation);
      } else {
        items = await Activity_Api.list(parametros, props.relation);
      }

      setDataGrid(items.data);

      const filteredData = items.data.map((item, index) => {
        return {
          id: item.id,
          parent: item.parent_id || 0,
          text: item.summary,
          droppable: true,
          data: item,
        };
      });
      if (isCheckboxOn) {
        setMultipleSelected(Activities?.data?.map((item: any) => item.id as string) || []);
        setWasSelectAllActivitiesClicked(false);
      }
      setData(filteredData);
      setTotal(items.pagination.total);
      setTotalPages(items.pagination.total_pages);
      if (isGridView) {
        selectRow<IDataActivity>({
          data: items.data,
          selected,
          currentSelectedRowIndex,
          rowToSelect,
          setSelected,
        });
      }
    } catch (error: IDataActivityErrors | unknown) {
      if (error) {
        const err = error as IDataActivityErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      newRow.status = newRow.status.value;
      const response = await Activity_Api.update(newRow, props.relation);
      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: 'Remover atividade',
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} o atividade{' '}
          <strong>
            {selected?.id} -{' '}
            {selected && selected.summary?.length > 20
              ? `${selected?.summary.substring(0, 20)}...`
              : selected?.summary}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (selected) await Activity_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataActivityErrors | unknown) {
      if (error) {
        const err = error as IDataActivityErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`, confirmTextButton: 'OK',
            message: err.errors.message,
            onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
            onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          });
      }
    }
  }

  function handleUpload(Activity: IDataActivity) {
    setSelected(Activity);
  }
  async function loadAllGridRows() {
    try {
      setLoadingAllGridRows(true);
      const items = await Activity_Api.list(
        { q: search, page: 1, per_page: total, ...params },
        props.relation
      );
      items.data.forEach((item) => {
        item['created_at'] = formatDateBR(item['created_at']);
        item['updated_at'] = formatDateBR(item['updated_at']);
      });
      const fields = getColumns().map((item) => item.field);
      const result: SetStateAction<any[][]> = [
        fields,
        ...items.data.map((obj) => fields.map((key) => obj[key])),
      ];
      const filteredResult = filterObjectColumns(result);
      filteredResult[0] = header;
      setAllPageRows(filteredResult);
      setLoadingAllGridRows(false);
      csvLink?.current?.link?.click();
    } catch (error: IDataActivityErrors | unknown) {
      setLoadingAllGridRows(false);
      if (error) {
        const err = error as IDataActivityErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    }
  }

  const setCloneModal = () => {
    setCloneActivities({
      open: true,
      title: 'Clonar atividades do Workflow',
      message: <span>Deseja clonar essas atividades?</span>,
      onClose: () => setCloneActivities({ ...cloneActivities, open: false }),
      onConfirm: () => setCloneActivities({ ...cloneActivities, open: false }),
    });
  };

  function onFilter(filters: IParamsQs) {
    setPage(0);
    setSelected(undefined);
    setRowToSelect(null);
    setParams({ ...filters, page: 0 });
  }

  async function handleUpdate(summary: string) {
    if (selected) {
      setLoading(true);
      try {
        await Activity_Api.update({ ...(selected as IDataActivity), summary }, props.relation);

        load();
        setToast({
          open: true,
          message: UPDATE_MSG,
          severity: initialToast.severity,
        });
      } catch (error: IDataActivityErrors | unknown) {
        const err = error as IDataActivityErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`, confirmTextButton: 'OK',
            message: err.errors.message,
            onClose: () => setAlertModal({ ...alertModal, open: false }),
            onConfirm: () => setAlertModal({ ...alertModal, open: false }),
          });
      } finally {
        setLoading(false);
      }
    }
  }

  const ToolbarSelectionMessage = () => (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {multipleSelected.length < data.length && (
        <span>{`${multipleSelected.length} registro${multipleSelected.length >= 2 ? 's' : ''
          } selecionado${multipleSelected.length >= 2 ? 's' : ''}.`}</span>
      )}
      {!wasSelectAllActivitiesClicked &&
        data?.length < total &&
        multipleSelected?.length > 0 &&
        multipleSelected?.length === data.length && (
          <span>{`Todos os ${multipleSelected.length} registros estão selecionados.`}</span>
        )}
      {!wasSelectAllActivitiesClicked &&
        data?.length === total &&
        multipleSelected?.length === total &&
        multipleSelected.length === data.length && (
          <span>{`Todos os ${total} registros estão selecionados.`}</span>
        )}
      {wasSelectAllActivitiesClicked && multipleSelected?.length === data?.length && (
        <span>{`Todos os ${total} registros estão selecionados.`}</span>
      )}
      {multipleSelected.length < total && !isAllPageRowsSelected && (
        <Button
          onClick={() => {
            setMultipleSelected(data.map((item) => item?.id as string));
            setWasSelectAllActivitiesClicked(true);
            selectAllPageRows(true);
          }}
        >
          {`Selecionar todos os ${total} registros`}
        </Button>
      )}
      {((wasSelectAllActivitiesClicked && data.length === multipleSelected.length) ||
        (data.length === total && multipleSelected.length === total)) && (
          <Button
            onClick={() => {
              setWasSelectAllActivitiesClicked(false);
              selectAllPageRows(false);
              setMultipleSelected([]);
              setIsCheckboxOn(false);
            }}
          >
            Limpar seleção
          </Button>
        )}
    </div>
  );

  const ToolbarComponent = (props) => (
    <>
      <Toolbar
        data={data}
        rowSelection={selected}
        extraButtons={[
          {
            type: 'icon',
            visible: true,
            disabled: !multipleSelected?.length || !isCheckboxOn,
            icon: 'copy_all',
            title: 'Copiar atividades',
            onClick: () => setCloneModal(),
          },
        ]}
        options={{
          total: {
            value: total,
            total_pages: totalPages,
          },
          cancel: {
            onClick: () => setSelected(undefined),
          },
          view: {
            state: isGridView ? 'grid' : 'form',
            clone_items: true,
          },
          filter: {
            columns: getColumns().filter((col) => col.field !== 'duration'),
            onFilter: onFilter,
          },
          page: page + 1,
          isLoadingAllGridRows: isLoadingAllGridRows,
          isLoadingGrid: loading,
          data: data,
          isCheckboxOn: isCheckboxOn,
          multipleSelected: multipleSelected,
          selectedRow: selected,
        }}
        onAddClick={() => {
          toggleView(false);
          setSelected(undefined);
          setTypeForm('create')
        }}
        onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
        onClickView={() => toggleView(!isGridView)}
        onClickRefresh={() => load()}
        onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
        onClickAttach={() => toggleAttachModal(true)}
        searchValue={search}
        onSearchChange={(value) => setSearch(value)}
        onResetForm={() => handleResetForm('ActivityForm')}
        onSaveRow={() => setOnSaveRow('save')}
        exportGridData={() => loadAllGridRows()}
        navigate={(value) => {
          setSelected(value);
        }}
        navigateToPage={(page, rowToSelect = 'first') => {
          setRowToSelect(rowToSelect);
          setPage(page - 1);
        }}
        selectAllPageRows={(isAllPageRowsAlreadySelected) => {
          if (isAllPageRowsAlreadySelected === false) {
            setMultipleSelected([]);
          }
          setIsCheckboxOn((prev) => !prev);
          setMultipleSelected(data.map((item) => item.id as number));
        }}
        {...props}
      />
      {isCheckboxOn &&
        !!multipleSelected?.length &&
        !loading &&
        !props.hideToolbarSelectionMessage && <ToolbarSelectionMessage />}
    </>
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3}>
          {!isGridView && <ToolbarComponent />}
          {isGridView && props.view === 'grid' && (
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  setSelected(event.row);
                  toggleView(false);
                }
              }}
              columns={getColumns({
                relation: workflowCtx
                  ? { id: workflowCtx.id as number, relation: 'Workflow' }
                  : undefined,
                hasParent: Boolean(selected?.parent_id),
              })}
              rows={dataGrid}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setIsCheckboxOn(false);
                setWasSelectAllActivitiesClicked(false);
                setMultipleSelected([]);
                setPage(newPage);
                setRowToSelect(null);
              }}
              //onPageChange={(newPage) => setPage(newPage)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(model) => {
                handleOnSortModelChange(model, setParams, setRowToSelect);
              }}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={(error) => {
                const errorMsg = error.errors
                  ? convertErroToString(error.errors)
                  : error.message || 'Não foi possível atualizar.';
                showToast({
                  message: errorMsg,
                  severity: 'error',
                  open: true,
                });
              }}
              checkboxSelection={isCheckboxOn}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                if (isCheckboxOn) {
                  setWasSelectAllActivitiesClicked(false);
                  selectAllPageRows(null);
                  setMultipleSelected(newSelection as string[]);
                }
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]?.data);
              }}
              selectionModel={isCheckboxOn ? multipleSelected : [selected?.id as number]}
              customToolbar={() => (
                <ToolbarComponent hasExportButton isGridRef fileName="UMAMI _ Atividades" />
              )}
            />
          )}
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="Atividades"
            separator=";"
          />
          {isGridView && props.view !== 'grid' && (
            <>
              <ToolbarComponent hasExportButton />
              <TreeViewActivity
                loading={loading}
                nodeSelected={
                  selected
                    ? {
                      id: selected.id as number,
                      text: selected.summary,
                      parent: selected.parent_id as number,
                      data: selected,
                    }
                    : undefined
                }
                clearSelected={!Boolean(selected)}
                onClickNode={(node) => setSelected(node.data)}
                data={data}
                onDrop={() => load()}
                onUpdate={(node, newValue) => handleUpdate(newValue)}
                relation={props.relation}
              />
            </>
          )}
          {!isGridView && props.type !== 'subatividades' && (
            <ActivityFormContainer
              uuid={uuid}
              key={JSON.stringify(selected)}
              setSelected={(data) => setSelected(data)}
              data={selected}
              predecessora={
                props.type === 'sucessoras' && activityCtx ? activityCtx.data : undefined
              }
              relation={props.relation}
              onSubmit={(item) => {
                if (props.sequency && props.onSubmit) {
                  props.onSubmit()
                } else {
                  load()
                  setSelected(item)
                }
              }}
              cancel={() => toggleView(!isGridView)}
              onClickAttach={() => toggleAttachModal(true)}
              campos={props.campos}
              sequency={props.sequency}
              typeForm={typeForm}
            />
          )}
          {!isGridView && activityCtx && props.type === 'subatividades' && (
            <SubActivityFormContainer
              uuid={uuid}
              key={JSON.stringify(selected)}
              setSelected={(data) => setSelected(data)}
              data={selected}
              relation={props.relation}
              onSubmit={() => {
                if (props.sequency && props.onSubmit) {
                  props.onSubmit()
                } else {
                  load()
                }
              }}
              cancel={() => toggleView(!isGridView)}
              onClickAttach={() => toggleAttachModal(true)}
            />
          )}
        </Grid>
        {selected && (
          <CreateAttachment
            data={selected}
            open={openAttach}
            onClose={() => toggleAttachModal(false)}
            onUpload={handleUpload}
          />
        )}
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
          confirmTextButton={alertModal?.confirmTextButton}
        />
        <CloneActivities
          open={cloneActivities?.open}
          loading={cloneActivities?.loading}
          title={cloneActivities?.title}
          message={cloneActivities?.message}
          activities={multipleSelected}
          onClose={cloneActivities.onClose}
          onConfirm={cloneActivities.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayActivity;

import { yupResolver } from '@hookform/resolvers/yup';
import { PhotoCamera } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Segmentation_Api from 'app/api/Segmentation_Api';
import AlertMessage from 'app/components/Alert';
import SelectSegments from 'app/components/Inputs/Selects/SelectSegment/SelectSegment';
import SelectSegmentationModel from 'app/components/Inputs/Selects/SelectSegmentationModel/SelectSegmentationModel';
import SelectTreeLibrary from 'app/components/Inputs/Selects/SelectTreeLibrary/SelectTreeLibrary';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataSegmentation, IDataSegmentationErrors } from 'app/types/data/IDataSegmentation';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import CreateAttachment from 'app/views/curated/segmentation/components/CreateAttachment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SegmentationSchema } from '../@Schemas/SegmentationSchema';
import { IFormSegmentation } from '../@Types/IFormSegmentation';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataSegmentation = {
  id: '',
  name: '',
  modelo_segmentacao_id: null,
  segmento_segmentacao_id: null,
};

const UPDATE_MSG = getMessage('Biblioteca', 'update', 'a');
const CREATE_MSG = getMessage('Biblioteca', 'create', 'a');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SegmentationForm = (props: IFormSegmentation) => {
  const { resetFormList } = useUtilsContext();
  const { getConfigCampo } = useAuth();
  const [data, setData] = React.useState<IDataSegmentation>(props.data || initialValues);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [isImageImportOpened, setOpenImageImport] = React.useState(false);
  const [imageLoading, setMetaImageLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setData((props.data as IDataSegmentation) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.SegmentationForm]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataSegmentation>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(SegmentationSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataSegmentation) {
    const campos = [{
      nome: 'Biblioteca de Árvores Agnósticas',
      campo: 'origin_id'
    },
    {
      nome: 'Nome',
      campo: 'name'
    },
    {
      nome: 'Modelo de Segmentação',
      campo: 'modelo_segmentacao'
    },
    {
      nome: 'Segmento',
      campo: 'segmento_segmentacao'
    },
    {
      nome: 'Adicionar Imagem',
      campo: 'image'
    },
    ]

    campos.forEach(x => {
      if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required && data[x.nome] !== "ID")
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })
    setLoading(true);
    try {
      var newData: IDataSegmentation;
      if (data.id) {
        newData = await Segmentation_Api.update(data, props.relation);
      } else {
        newData = await Segmentation_Api.create(data, props.relation);
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
      if (props.onSubmit) props.onSubmit(newData);
    } catch (error: IDataSegmentationErrors | unknown) {
      if (error) {
        const err = error as IDataSegmentationErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteImage = async () => {
    const midiaId = !!data?.image ? data?.image[0].id : null;
    if (midiaId) {
      try {
        setMetaImageLoading(true);
        await Segmentation_Api.detachMetaImage(midiaId);
        const newData = await Segmentation_Api.show(data);
        if (props?.refreshGrid) props?.refreshGrid();
        setDataValue(newData, setValue);
        setData(newData);
        setToast({
          open: true,
          message: 'Imagem deletada com sucesso',
          severity: 'success',
        });
        setMetaImageLoading(false);
      } catch (error) {
        setMetaImageLoading(false);
        setToast({
          open: true,
          message: 'Não foi possível deletar a imagem',
          severity: 'error',
        });
      }
    }
  };

  const updateForm = (data) => {
    setDataValue(data, setValue);
    setData(data);
  };

  return (
    <>
      <FormBox id="formik-Segmentation" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {getConfigCampo && !getConfigCampo('Biblioteca de Árvores Agnósticas', props.campos ?? []).hidden && props.relation && (
            <>
              <Grid item xs={12} marginBottom={3}>
                <SelectTreeLibrary
                  //relation={props.relation}
                  label="Biblioteca de Árvores Agnósticas"
                  onChange={(segmentation) => {
                    if (segmentation) {
                      const newSegmentation = {
                        ...segmentation,
                        id: null,
                        origin_id: segmentation.id,
                      };
                      setData(newSegmentation);
                      setDataValue(newSegmentation, setValue);
                    } else {
                      setData(initialValues);
                      setDataValue(initialValues, setValue);
                    }
                  }}
                />
              </Grid>
            </>
          )}
          {getConfigCampo && !getConfigCampo('id', props.campos ?? []).hidden && props.type === 'update' && (
            <>
              <Grid item xs={12} md={3}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={data.id}
                  render={({ field: { onChange } }) => (
                    <TextField
                      id="id"
                      label="ID"
                      type="text"
                      disabled={true}
                      value={data.id}
                      autoComplete={'off'}
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: Boolean(data.id) }}
                      onChange={(e) => {
                        setData({ ...data, id: parseInt(e.target.value) });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={9}></Grid>
            </>
          )}

          {getConfigCampo && !getConfigCampo('Nome', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={data.name}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-name"
                    label="Nome*"
                    type="text"
                    value={data.name}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.name?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Modelo de Segmentação', props.campos ?? []).hidden &&
            <Grid item xs={6}>
              <Controller
                name="modelo_segmentacao_id"
                control={control}
                defaultValue={data?.modelo_segmentacao_id}
                render={({ field: { onChange } }) => (
                  <SelectSegmentationModel
                    id="modelo_segmentacao_id"
                    value={data?.modelo_segmentacao}
                    onChange={(modelo_segmentacao) => {
                      setValue(
                        'modelo_segmentacao_id',
                        modelo_segmentacao ? (modelo_segmentacao.id as number) : null
                      );
                      setValue('modelo_segmentacao', modelo_segmentacao);
                      setData({
                        ...data,
                        modelo_segmentacao_id: modelo_segmentacao
                          ? (modelo_segmentacao.id as number)
                          : null,
                        modelo_segmentacao: modelo_segmentacao,
                      });
                    }}
                    errors={errors?.modelo_segmentacao_id}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Segmento', props.campos ?? []).hidden &&
            <Grid item xs={6}>
              <Controller
                name="segmento_segmentacao_id"
                control={control}
                defaultValue={data?.segmento_segmentacao_id}
                render={({ field: { onChange } }) => (
                  <SelectSegments
                    id="segmento_segmentacao_id"
                    value={data?.segmento_segmentacao}
                    onChange={(segmento_segmentacao) => {
                      setValue(
                        'segmento_segmentacao_id',
                        segmento_segmentacao ? (segmento_segmentacao.id as number) : null
                      );
                      setValue('segmento_segmentacao', segmento_segmentacao);
                      setData({
                        ...data,
                        segmento_segmentacao_id: segmento_segmentacao
                          ? (segmento_segmentacao.id as number)
                          : null,
                        segmento_segmentacao: segmento_segmentacao,
                      });
                    }}
                    errors={errors?.segmento_segmentacao_id}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Adicionar Imagem', props.campos ?? []).hidden &&
            <>
              {data?.id && (
                <Grid item xs={12}>
                  <Button
                    sx={{
                      marginTop: 1,
                    }}
                    onClick={() => setOpenImageImport(true)}
                    variant="outlined"
                    startIcon={<PhotoCamera />}
                    disabled={Boolean(data?.image?.length)}
                  >
                    <span>Adicionar Imagem</span>
                  </Button>
                </Grid>
              )}

              {Boolean(data?.image?.length) && (
                <Grid item xs={12} mt={2}>
                  <Paper
                    elevation={3}
                    sx={{ position: 'relative', width: 'max-content', padding: '8px' }}
                  >
                    <img src={data?.image?.[0]?.original_url} alt="meta_image" width="200px" />
                    <IconButton
                      aria-label="delete"
                      size="small"
                      style={{ position: 'absolute', bottom: -20, right: -25 }}
                      color="error"
                    >
                      {imageLoading ? (
                        <Fab size="small" color="error" aria-label="add">
                          <CircularProgress size={22} color="inherit" />
                        </Fab>
                      ) : (
                        <Tooltip title="Remover imagem">
                          <Fab size="small" color="error" aria-label="add">
                            <DeleteIcon fontSize="small" onClick={handleDeleteImage} />
                          </Fab>
                        </Tooltip>
                      )}
                    </IconButton>
                  </Paper>
                </Grid>
              )}
              <CreateAttachment
                multiple={false}
                data={data}
                open={isImageImportOpened}
                onClose={() => setOpenImageImport(false)}
                format="image"
                refreshGrid={props?.refreshGrid}
                updateForm={updateForm}
              />
            </>
          }
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton disabled={props.editDisabled} type="submit" color="primary" loading={loading} variant="contained">
            {props.typeForm && props.typeForm === 'create' && props.sequency ? (<>Salvar e continuar</>) : <>Salvar</>}
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default SegmentationForm;

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, styled, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import WorkflowPhases_Api from 'app/api/WorkflowPhases_Api';
import AlertMessage from 'app/components/Alert';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataWorkflowPhases, IDataWorkflowPhasesErrors } from 'app/types/data/IDataWorkflowPhases';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { WorkflowPhasesSchema } from '../@Schemas/WorkflowPhasesSchema';
import { IFormWorkflowPhases } from '../@Types/IFormWorkflowPhases';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataWorkflowPhases = {
  id: '',
  name: '',
  description: '',
  started_in: null,
  solve_until: null,
};

const UPDATE_MSG = getMessage('Etapa', 'update');
const CREATE_MSG = getMessage('Etapa', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const WorkflowPhasesForm = (props: IFormWorkflowPhases) => {
  const { resetFormList } = useUtilsContext();
  const { getConfigCampo } = useAuth();

  const [data, setData] = React.useState<IDataWorkflowPhases>(props.data || initialValues);
  //const [comboData, setComboData] = React.useState<ISelectOption<IDataWorkflowPhases>[]>([]);
  /* const [comboOption, setComboOption] = React.useState<ISelectOption<IDataWorkflowPhases> | null>(
    props.data
      ? { value: props.data?.id as number, label: props.data?.name, data: props.data }
      : null
  ); */
  //const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  //const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    setData((props.data as IDataWorkflowPhases) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.WorkflowPhasesForm]);

  /* React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]); */

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataWorkflowPhases>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(WorkflowPhasesSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  /* async function load() {
    setComboLoading(true);
    try {
      const items = await WorkflowPhases_Api.list({ q: search }, props.relation);

      const combo: ISelectOption<IDataWorkflowPhases>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataWorkflowPhasesErrors | unknown) {
      if (error) {
        const err = error as IDataWorkflowPhasesErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  } */

  async function onSubmit(data: IDataWorkflowPhases) {
    const campos = [{
      nome: 'Nome',
      campo: 'name'
    },
    {
      nome: 'Descrição',
      campo: 'description'
    },
    {
      nome: 'Início',
      campo: 'started_in'
    },
    {
      nome: 'Fim',
      campo: 'solve_until'
    },
    ]

    campos.forEach(x => {
      if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required && data[x.nome] !== "ID")
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })
    setLoading(true);
    try {
      var newData: IDataWorkflowPhases;
      if (data.id) {
        newData = await WorkflowPhases_Api.update(data, props.relation);
      } else {
        newData = await WorkflowPhases_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit(newData);
      }
      if (props.typeForm && props.typeForm === 'create' && props.sequency && props.onSubmit) {
        props.onSubmit()
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataWorkflowPhasesErrors | unknown) {
      if (error) {
        const err = error as IDataWorkflowPhasesErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <FormBox id="formik-WorkflowPhases" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {/* {props.relation && (
            <>
              <Grid item xs={12} marginBottom={2}>
                <Autocomplete
                  noOptionsText="Nenhuma Etapa"
                  options={comboData}
                  defaultValue={comboOption}
                  value={comboOption}
                  loading={comboLoading}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(event, option) => {
                    if (option && option.data) {
                      const data = { ...option.data, id: null };
                      setData(data);
                      setComboOption(option);
                      setDataValue(data, setValue);
                      if (props.onChangeRelation) props.onChangeRelation(data);
                    } else {
                      setData(initialValues);
                      setComboOption(null);
                      setDataValue(initialValues, setValue);
                      if (props.onChangeRelation) props.onChangeRelation(undefined);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography noWrap>
                        [{option.value}] {option.label}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Etapas"
                      placeholder="Pesquisar uma etapa"
                      type="text"
                      size="small"
                      onChange={(e) => setSearch(e.target.value)}
                      helperText={
                        <Typography color="red" variant="caption" component="span">
                          {errors.id?.message}
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            </>
          )} */}
          {props.type === 'update' && (
            <>
              <Grid item xs={12} md={3}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={data.id}
                  render={({ field: { onChange } }) => (
                    <TextField
                      id="id"
                      label="ID"
                      type="text"
                      disabled={true}
                      value={data.id}
                      autoComplete={'off'}
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: Boolean(data.id) }}
                      onChange={(e) => {
                        setData({ ...data, id: parseInt(e.target.value) });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={9}></Grid>
            </>
          )}

          {getConfigCampo && !getConfigCampo('Nome', props.campos ?? []).hidden &&
            <Grid item xs={12} md={10}>
              <Controller
                name="name"
                control={control}
                defaultValue={data.name}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-name"
                    label="Nome*"
                    type="text"
                    value={data.name}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.name?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Descrição', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={data.description}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-description"
                    label="Descrição"
                    type="text"
                    multiline
                    rows={3}
                    value={data.description}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.description?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Início', props.campos ?? []).hidden &&
            <Grid item xs={12} md={6}>
              <Controller
                name="started_in"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Início"
                      value={data.started_in ? moment(data.started_in).toDate() : null}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, started_in: moment(newDate).toDate() });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Início"
                          id="phases_started_in"
                          fullWidth
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.started_in?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Fim', props.campos ?? []).hidden &&
            <Grid item xs={12} md={6}>
              <Controller
                name="solve_until"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Fim"
                      value={data.solve_until ? moment(data.solve_until).toDate() : null}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, solve_until: moment(newDate).toDate() });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Fim"
                          id="phases_solve_until"
                          size="small"
                          fullWidth
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.solve_until?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }


        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
            {props.typeForm && props.typeForm === 'create' && props.sequency ? (
              <>Salvar e continuar</>
            ) : (<>Salvar</>)
            }
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default WorkflowPhasesForm;

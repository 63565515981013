import FormContainer from 'app/components/Containers/FormContainer';
import { LocalsForm } from 'app/components/Forms/@Types/IFormAttribute';
import AttributeForm from 'app/components/Forms/Attributes';
import { IDataRelation } from 'app/types/data/IData';
import { AttributeRelations, IDataAttribute } from 'app/types/data/IDataAttribute';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { IDataPartner } from 'app/types/data/IDataPartner';
import React from 'react';

declare interface IAttributeFormContainer {
  data?: IDataAttribute;
  relation?: IDataRelation<AttributeRelations>;
  partner?: IDataPartner;
  onClickAttach?: () => void;
  onSubmit: (item: any, data?) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  local?: LocalsForm;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const AttributeFormContainer = (props: IAttributeFormContainer) => {
  const [data, setData] = React.useState<IDataAttribute | undefined>(props.data);
  React.useEffect(() => setData(props.data), [props.data]);




  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <AttributeForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            partner={props.partner}
            local={props.local}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
      />
    </>
  );
};

export default AttributeFormContainer;

import { styled, Card, alpha } from '@mui/material';
import { DataGrid, DataGridProps, gridClasses, GridInitialState, GridPagination } from '@mui/x-data-grid';
import { FunctionComponent, forwardRef, useImperativeHandle, useRef } from 'react';

const ODD_OPACITY = 0.15;
const MINUS_OPACITY = 0.35;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: alpha(theme.palette.success.light, ODD_OPACITY),
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.success.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.success.main,
        MINUS_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.success.main,
          MINUS_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.success.main,
            MINUS_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.success.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.success.main,
        MINUS_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.success.main,
          MINUS_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.success.main,
            MINUS_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  '[data-field="id"]:hover': {
    cursor: 'default',
  },
}));

const initialState: GridInitialState = {
  pagination: {
    pageSize: 10,
  },
};

declare interface ITableMui extends DataGridProps {
  customToolbar?: FunctionComponent;
}

const TableMUI = forwardRef((props: ITableMui, ref) => {

  const localRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    someMethod: () => {
      alert('Child method called!');
    }
  }));

  return (
    <Card elevation={3} sx={{ height: '100%' }}>
      <StripedDataGrid
        ref={localRef}
        components={{
          Toolbar: props.customToolbar,
          Pagination: GridPagination,
        }}
        pagination
        paginationMode={props.paginationMode || 'server'}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        componentsProps={{
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          }
        }}
        density={props.density || 'compact'}
        editMode={props.editMode || 'row'}
        experimentalFeatures={props.experimentalFeatures || { newEditingApi: true }}
        getRowClassName={
          props.getRowClassName
            ? props.getRowClassName
            : (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')
        }
        initialState={props.initialState || initialState}
        disableColumnFilter={props.disableColumnFilter || true}
        sortingMode={props.sortingMode || 'server'}
        {...props}
      />
    </Card>
  );
});

export default TableMUI;

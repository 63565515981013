import React from 'react';
import { useLocation } from 'react-router-dom';

import Comment_Api from 'app/api/Comment_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import CommentForm from 'app/components/Forms/Comment';
import { IDataComment, CommentRelations } from 'app/types/data/IDataComment';
import { IDataRelation } from 'app/types/data/IData';
import CommentContext from 'app/contexts/CommentContext';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
declare interface ICommentFormContainer {
  data?: IDataComment;
  relation?: IDataRelation<CommentRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const CommentFormContainer = (props: ICommentFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataComment | undefined>(props.data);
  const [activeTab, setActiveTab] = React.useState<string>('0')
  function getMenuSequency(sequency?: any, menus?: any[]) {
    return menus?.find(x => x.sequency === sequency)
  }


  React.useEffect(() => setData(props.data), [props.data]);

  async function getItemsMenu() {
    try {

      const tabMenu: any = [
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={Comment_Api}
              onClickAttach={props.onClickAttach}
              campos={props.campos}
              sequency={props.sequency}
            />
          ),
        },
      ]
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }

      let res = await BpmOrder_Api.getBpmItem(funcionalidade.id)

      if (res && res.length > 0) {
        res = res?.filter((item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => (
            t.funcionalidade_id === item.funcionalidade_id
          ))
        )
        let nItems = res.map((itemRes: any) => {
          return {
            ...itemRes,
            campos: itemRes?.campos?.map(campo => {
              return {
                ...campo,
                nome: campo.funcionalidade_campo.nome,
              }
            })
          }
        })

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];

        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));


        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const nomeTab = x.nome.split(' - ')
              nTabs.push({
                ...menuItem, ...x,
                title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
              })
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  return (
    <CommentContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        mainForm={
          <CommentForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
          />
        }
        tabs={
          !data?.id
            ? undefined
            : tabsMenu
        }
      />
    </CommentContext.Provider>
  );
};

export default CommentFormContainer;

import React from 'react';
import { useLocation } from 'react-router-dom';

import FormContainer from 'app/components/Containers/FormContainer';
import FormGroupUser from 'app/components/Forms/GroupUser';
import FormPermissions from 'app/components/Forms/Permissions';
import { IDataRelation } from 'app/types/data/IData';
import { GroupUserRelations, IDataGroupUser } from 'app/types/data/IDataGroupUser';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import MenuItem_Api from 'app/api/MenuItem_Api';

import useAuth from 'app/hooks/useAuth';
import { IMenuItemPagination } from 'app/types/data/IDataMenuItem';
declare interface IGroupUserFormContainer {
  data?: IDataGroupUser;
  relation?: IDataRelation<GroupUserRelations>;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
}

const GroupUserFormContainer = (props: IGroupUserFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataGroupUser | undefined>(props.data);
  const [listPermissions, setDataPermissions] = React.useState<any[]>([]);

  async function getItemsMenu() {
    try {
      const listTags: string[] = []
      // if (data?.menu_id) {
      //   const resItems: IMenuItemPagination = await MenuItem_Api.list({ menu_id: data.menu_id, page: 1, per_page: 200 });

      //   resItems.data.map((x: any) => {
      //     if (x.funcionalidade_id) {
      //       listTags.push(x.funcionalidade ? x.funcionalidade.tags : x.tags ? x.tags : '')
      //     }
      //   })
      // }

      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)
      const tabMenu: any = [{ mainName: 'Permissões', title: 'Permissões', component: <FormPermissions setFilteredPermissions={(list) => handleFilteredPermissions(list)} role={data!} customMenuTags={listTags} /> }]

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }

      const res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      if (res && res.length > 0) {

        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hide = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title,
                  mainName: menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleFilteredPermissions(list: any) {
    setDataPermissions(JSON.parse(JSON.stringify(list)))
  }

  async function handlePermissions(menuID: number) {
    try {
      const res: IMenuItemPagination = await MenuItem_Api.list({ menu_id: menuID, page: 1, per_page: 200 });
      const listTags: string[] = []

      // res.data.map((x: any) => {
      //   if (x.funcionalidade_id) {
      //     listTags.push(x.funcionalidade ? x.funcionalidade.tags : x.tags ? x.tags : '')
      //   }
      // })

      const idxTab = tabsMenu.findIndex(x => x.mainName === 'Permissões')
      if (idxTab !== -1) {
        const listtabs = tabsMenu
        listtabs[idxTab] = {
          mainName: 'Permissões',
          title: listtabs[idxTab].title, component: <FormPermissions setFilteredPermissions={(list) => handleFilteredPermissions(list)} role={data!} customMenuTags={listTags} />
        }
        setTabsMenu([...listtabs])
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  return (
    <FormContainer
      mainForm={
        <FormGroupUser
          setSelected={(data) => props.setSelected(data)}
          data={data}
          permissions={listPermissions}
          relation={props.relation}
          onChangeRelation={(selectedData) => setData(selectedData)}
          type="update"
          onSubmit={props.onSubmit}
          onCancel={props.cancel}
          setSelectedMenu={handlePermissions}
        />
      }
      tabs={data && tabsMenu}
    />
  );
};

export default GroupUserFormContainer;

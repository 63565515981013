import { Autocomplete, InputAdornment, Typography } from '@mui/material';
import Categories_Api from 'app/api/Categories_Api';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import ProjectContext from 'app/contexts/ProjectContext';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';
import {
  CategoryRelations,
  IDataCategory,
  IDataCategoryErrors,
} from 'app/types/data/IDataCategory';
import React, { useContext } from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import Option from '../Option/Option';
import { Category } from '@mui/icons-material';

export interface ISelectCategories {
  id?: string;
  value?: IDataCategory;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  //onChangeExtraOptions?: (options: CategoryExtraOptions[]) => void;
  relation?: IDataRelation<CategoryRelations>;
  onChange?: (categorie?: IDataCategory) => void;
  getCurrentSelected?: (data?: IDataCategory) => void;
}

const SelectCategoriesProduct = (props: ISelectCategories) => {
  const projectCtx = useContext(ProjectContext);
  const productCtx = useContext(CatalogProductContext);

  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataCategory>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataCategory> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      load();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataCategory> | undefined = props.value
      ? {
        label: props.value?.nm_categoria,
        value: props.value?.id as number,
        data: props.value,
      }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);





  async function load() {
    setLoading(true);
    try {
      const items = await Categories_Api.list(
        {
          q: search,
          page: 1,
          per_page: 24,
          order: 'asc',
          sort: 'id',
          projeto_id: projectCtx?.id ? (projectCtx?.id as number) : undefined,
        },
        props.relation
      );
      const combo: ISelectOption<IDataCategory>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.nm_categoria,
        data: item,
      }));

      setComboData(combo);

      if (!comboOption && !props.value)
        props.getCurrentSelected && props.getCurrentSelected(combo?.[0]?.data);
    } catch (error: IDataCategoryErrors | unknown) {
      if (error) {
        const err = error as IDataCategoryErrors;
        // eslint-disable-next-line eqeqeq
        if (err.errors.status != 403) setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Autocomplete
        id={props.id || `selectCategoriesProducts_${Math.random().toString(36).substring(6)}`}
        key={comboOption?.data?.id}
        noOptionsText="Nenhuma categoria encontrada"
        options={comboData}
        defaultValue={comboOption}
        value={comboOption}
        loading={loading}
        onClose={() => setSearch('')}
        disabled={props.disabled}
        //isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, option) => {
          if (option && option.data) {
            setComboOption(option);
            if (props.onChange) props.onChange(option.data);
          } else {
            setComboOption(undefined);
            if (props.onChange) props.onChange(undefined);
          }
        }}
        renderOption={(props, option) => (
          <Option
            key={option.value}
            props={props}
            option={option}
            secondary={option.data?.hierarquia}
          />
        )}
        renderInput={(params) => (
          <TimedTextField
            {...params}
            label={
              props.label || `Categorias ${productCtx && projectCtx ? 'do projeto' : 'Agnósticas'}`
            }
            placeholder={
              props.placeholder ||
              `Pesquisar uma Categoria ${productCtx && projectCtx ? 'do projeto' : 'da base agnóstica'
              }`
            }
            type="text"
            size="small"
            //timer={300}
            onChange={(e) => setSearch(e.target.value)}
            variant={props.variant || 'outlined'}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Category />
                </InputAdornment>
              ),
            }}
            helperText={
              <Typography color="red" variant="caption" component="span">
                {errors}
              </Typography>
            }
          />
        )}
      />
      {/* {comboOption && props.onChangeExtraOptions && (
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {to_keep_options.map((option) => (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={toKeep?.includes(option.value as CategoryExtraOptions)}
                      onChange={() => {
                        if (toKeep?.includes(option.value as CategoryExtraOptions)) {
                          const filteredOptions = toKeep.filter((opt) => opt !== option.value);
                          setToKeep(filteredOptions);
                        } else {
                          setToKeep([
                            ...(toKeep as CategoryExtraOptions[]),
                            option.value as CategoryExtraOptions,
                          ]);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={option.name}
                />
              </FormGroup>
            </div>
          ))}
        </Grid>
      )} */}
    </>
  );
};

export default SelectCategoriesProduct;

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, styled, TextField, Typography } from '@mui/material';
import Orientation_Api from 'app/api/Orientation_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataOrientation, IDataOrientationErrors } from 'app/types/data/IDataOrientation';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OrientationSchema } from '../@Schemas/OrientationSchema';
import { IFormOrientation } from '../@Types/IFormOrientation';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataOrientation = {
  id: '',
  ds_orientacao: '',
};

const UPDATE_MSG = getMessage('Orientação', 'update', 'a');
const CREATE_MSG = getMessage('Orientação', 'create', 'a');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const OrientationForm = (props: IFormOrientation) => {
  const { resetFormList } = useUtilsContext();
  const { getConfigCampo } = useAuth();

  const [data, setData] = React.useState<IDataOrientation>(props.data || initialValues);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataOrientation>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataOrientation> | null>(
    props.data
      ? { value: props.data?.id as number, label: props.data?.ds_orientacao, data: props.data }
      : null
  );
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search, setSearch] = React.useState<string>('');
  const [canViewBaseUmami, setCanViewBaseUmami] = React.useState<boolean>(false);

  React.useEffect(() => {
    setData((props.data as IDataOrientation) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.orientationForm]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataOrientation>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(OrientationSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function load() {
    setComboLoading(true);
    try {
      const items = await Orientation_Api.list({ q: search });

      const combo: ISelectOption<IDataOrientation>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.ds_orientacao,
        data: item,
      }));

      setCanViewBaseUmami(true);
      setComboData(combo);
    } catch (error: IDataOrientationErrors | unknown) {
      if (error) {
        const err = error as IDataOrientationErrors;
        // eslint-disable-next-line eqeqeq
        if (err.errors.status != '403')
          err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  async function onSubmit(data: IDataOrientation) {
    const campos = [{
      nome: 'descrição',
      campo: 'ds_orientacao'
    }]

    campos.forEach(x => {
      if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required && data[x.nome] !== "ID")
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })

    setLoading(true);
    try {
      var newData: IDataOrientation;
      if (data.id) {
        newData = await Orientation_Api.update(data, props.relation);
      } else {
        newData = await Orientation_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit(newData);
      }
      if (props.typeForm && props.typeForm === 'create' && props.sequency && props.onSubmit) {
        props.onSubmit()
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataOrientationErrors | unknown) {
      if (error) {
        const err = error as IDataOrientationErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <FormBox id="formik-orientation" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {props.relation && canViewBaseUmami && getConfigCampo && !getConfigCampo('orientações', props.campos ?? []).hidden && (
            <>
              <Grid item xs={12} marginBottom={2}>
                <Autocomplete
                  noOptionsText="Nenhuma Orientação"
                  options={comboData}
                  defaultValue={comboOption}
                  value={comboOption}
                  loading={comboLoading}
                  onClose={() => setSearch('')}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(event, option) => {
                    if (option && option.data) {
                      setData(option.data);
                      setComboOption(option);
                      setDataValue(option.data, setValue);
                      if (props.onChangeRelation) props.onChangeRelation(option.data);
                    } else {
                      setData(initialValues);
                      setComboOption(null);
                      setDataValue(initialValues, setValue);
                      if (props.onChangeRelation) props.onChangeRelation(undefined);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography noWrap>
                        [{option.value}] {option.label}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TimedTextField
                      {...params}
                      label="Orientações"
                      placeholder="Pesquisar uma orientação"
                      type="text"
                      size="small"
                      onChange={(e) => setSearch(e.target.value)}
                      helperText={
                        <Typography color="red" variant="caption" component="span">
                          {errors.id?.message}
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            </>
          )}
          {props.type === 'update' && (
            <>
              <Grid item xs={12} md={3}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={data.id}
                  render={({ field: { onChange } }) => (
                    <TextField
                      id="id"
                      label="ID"
                      type="text"
                      disabled={true}
                      value={data.id}
                      autoComplete={'off'}
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: Boolean(data.id) }}
                      onChange={(e) => {
                        setData({ ...data, id: parseInt(e.target.value) });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={9}></Grid>
            </>
          )}
          {getConfigCampo && !getConfigCampo('descrição', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="ds_orientacao"
                control={control}
                defaultValue={data.ds_orientacao}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-orientacao-ds"
                    label="Descrição*"
                    type="text"
                    multiline
                    rows={10}
                    value={data.ds_orientacao}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.ds_orientacao?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, ds_orientacao: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton disabled={props.editDisabled} type="submit" color="primary" loading={loading} variant="contained">
            {props.typeForm && props.typeForm === 'create' && props.sequency ?
              (<>Salvar e continuar</>) : (<>Salvar</>)
            }
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default OrientationForm;

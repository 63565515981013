import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Profile_Api from 'app/api/Profile_Api';
import User_Api from 'app/api/User_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataUser, IDataUserErrors } from 'app/types/data/IDataUser';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UserSchemaCreate, UserSchemaUpdate } from '../@Schemas/UserSchema';
import { IFormUser } from '../@Types/IFormUser';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataUser = {
  id: null,
  name: '',
  email: '',
  registration: '',
  password: '',
  c_password: '',
  avatar: '',
  is_curator: false,
};

const UPDATE_MSG = getMessage('Usuário', 'update');
const CREATE_MSG = getMessage('Usuário', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const FormUser = (props: IFormUser) => {
  const { resetFormList } = useUtilsContext();
  const [data, setData] = React.useState<IDataUser>(props.data || initialValues);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataUser>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataUser> | null>(null);
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    setData((props.data as IDataUser) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.userForm]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  async function load() {
    setComboLoading(true);
    try {
      const items = await User_Api.list({ q: search });

      const combo: ISelectOption<IDataUser>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataUserErrors | unknown) {
      if (error) {
        const err = error as IDataUserErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
    getValues,
  } = useForm<IDataUser>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(props.type === 'update' ? UserSchemaUpdate : UserSchemaCreate),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataUser) {
    setLoading(true);
    try {
      var newData: IDataUser;
      if (props.profile) await Profile_Api.update(data);
      if (data.id) newData = await User_Api.update(data, props.relation);
      else {
        newData = await User_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit();
      }
      setData(newData);
      setValue('id', newData.id);
      setDataValue(newData, setValue);
      //setData({ ...data, password: '', c_password: '' });
      //reset()
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataUserErrors | unknown) {
      if (error) {
        const err = error as IDataUserErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <FormBox id="formik-form-user" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>
        {props.relation && (
          <>
            <Grid item xs={12} md={8}>
              <Autocomplete
                noOptionsText="Nenhum usuário"
                options={comboData}
                defaultValue={comboOption}
                value={comboOption}
                loading={comboLoading}
                onClose={() => setSearch('')}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, option) => {
                  if (option && option.data) {
                    setData(option.data);
                    setComboOption(option);
                    setDataValue(option.data, setValue);
                  } else {
                    setData(initialValues);
                    setComboOption(null);
                    setDataValue(initialValues, setValue);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography noWrap>
                      [{option.value}] {option.label}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TimedTextField
                    {...params}
                    label="Usuários"
                    placeholder="Pesquisar um usuário"
                    type="text"
                    size="small"
                    onChange={(e) => setSearch(e.target.value)}
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.id?.message}
                      </Typography>
                    }
                  />
                )}
              />
            </Grid>
          </>
        )}
        {props.type === 'update' && (
          <Grid item xs={12} md={3}>
            <Controller
              name="id"
              control={control}
              defaultValue={data.id}
              render={({ field: { onChange } }) => (
                <TextField
                  id="form-user-id"
                  label="ID"
                  type="text"
                  disabled={true}
                  value={data.id}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setData({ ...data, id: parseInt(e.target.value) });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={props.type === 'update' ? 9 : 12}>
          <Controller
            name="email"
            control={control}
            defaultValue={data.email}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-user-email"
                label="E-mail*"
                type="text"
                value={data.email}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.email?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            defaultValue={data.name}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-user-nome"
                label="Nome*"
                type="text"
                value={data.name}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.name?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="registration"
            control={control}
            defaultValue={data.registration}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-user-registration"
                label="Matrícula"
                type="text"
                value={data.registration}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.registration?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, registration: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="password"
            control={control}
            defaultValue={data.password}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-user-password"
                label="Senha*"
                type="password"
                value={data.password}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.password?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="c_password"
            control={control}
            defaultValue={data.c_password}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-user-c-password"
                label="Confirmação de Senha*"
                type="password"
                value={data.c_password}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.c_password?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, c_password: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="is_curator"
            control={control}
            defaultValue={data.is_curator}
            render={({ field: { onChange } }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(data.is_curator)}
                      onChange={(e) => {
                        setData({ ...data, is_curator: !getValues('is_curator') });
                        onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Curador"
                />
              </FormGroup>
            )}
          />
        </Grid>
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        {!props.profile && (
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
        )}
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          {data.id &&
            <>Salvar</>
          }
          {props.sequency && !data.id &&
            <>Salvar e continuar</>
          }
          {!props.sequency && !data.id &&
            <>Salvar</>
          }
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default FormUser;

import { useLocation } from 'react-router-dom';
import Activity_Api from 'app/api/Activity_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import FormActivity from 'app/components/Forms/Activity';
import NotificationConfForm from 'app/components/Forms/Activity/NotificationConfForm';
import WorkflowSubActivityContext from 'app/contexts/WorkflowSubActivityContext';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import DisplayComment from 'app/views/@general/comment/components/DisplayComment';
import DisplayReasons from 'app/views/curated/reasons/components/DisplayReasons';
import React from 'react';
import DisplayActivity from '../../DisplayActivity';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
declare interface ISubActivityFormContainer {
  data?: IDataActivity;
  relation?: IDataRelation<ActivityRelations>;
  onClickAttach?: () => void;
  onSubmit: () => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid: string;
}

const SubActivityFormContainer = (props: ISubActivityFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataActivity | undefined>(props.data);
  const [activeTab, setActiveTab] = React.useState<string>('0')
  function getMenuSequency(sequency?: any, menus?: any[]) {
    return menus?.find(x => x.sequency === sequency)
  }
  let tabMenu: any = [
    {
      title: 'Sub-Atividades',
      component: (
        <DisplayActivity
          displayName="Sub-Atividades"
          relation={{ id: props.data!.id as number, relation: 'Activity' }}
          view="grid"
        />
      ),
    },
    {
      title: 'Motivos',
      component: (
        <DisplayReasons relation={{ id: props.data!.id as number, relation: 'Activity' }} />
      ),
    },
    {
      title: 'Comentários',
      component: (
        <DisplayComment relation={{ id: props.data!.id as number, relation: 'Activity' }} />
      ),
    },
    {
      title: 'Notificações',
      component: (
        <NotificationConfForm
          relation={{ id: data?.id as number, relation: 'Activity' }}
          refreshGrid={props.onSubmit}
        />
      ),
    },
    {
      title: 'Anexos',
      component: (
        <GridAttachments
          relationData={data}
          apiModel={Activity_Api}
          onClickAttach={props.onClickAttach}
        />
      ),
    },
  ]

  async function getItemsMenu() {
    try {
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      if (res && res.length > 0) {
        res = res?.filter((item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => (
            t.funcionalidade_id === item.funcionalidade_id
          ))
        )
        let nItems = res.map((itemRes: any) => {
          return {
            ...itemRes,
            campos: itemRes?.campos?.map(campo => {
              return {
                ...campo,
                nome: campo.funcionalidade_campo.nome,
              }
            })
          }
        })

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];

        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        tabMenu = [
          {
            title: 'Sub-Atividades',
            component: (
              <DisplayActivity
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.sequency]?.nome)
                }}
                displayName="Sub-Atividades"
                relation={{ id: props.data!.id as number, relation: 'Activity' }}
                view="grid"
              />
            ),
          },
          {
            title: 'Motivos',
            component: (
              <DisplayReasons relation={{ id: props.data!.id as number, relation: 'Activity' }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'motivos')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'motivos')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'motivos')?.sequency]?.nome)
                }}

              />
            ),
          },
          {
            title: 'Comentários',
            component: (
              <DisplayComment relation={{ id: props.data!.id as number, relation: 'Activity' }}

                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'comentários')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'comentários')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'comentários')?.sequency]?.nome)
                }}
              />
            ),
          },
          {
            title: 'Notificações',
            component: (
              <NotificationConfForm
                relation={{ id: data?.id as number, relation: 'Activity' }}
                refreshGrid={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'notificações')?.sequency);
                  if (props.onSubmit)
                    props.onSubmit();
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'notificações')?.sequency}
                onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'notificações')?.sequency)}
              />
            ),
          },
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                relationData={data}
                apiModel={Activity_Api}
                onClickAttach={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency);
                  if (props.onClickAttach)
                    props.onClickAttach();
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency}
                onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency)}
              />
            ),
          },
        ]
        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      }
      else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);

  return (
    <WorkflowSubActivityContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <FormActivity
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={props.onSubmit}
            onCancel={props.cancel}
            isSub
          />
        }
        tabs={
          data && tabsMenu
        }
      />
    </WorkflowSubActivityContext.Provider>
  );
};

export default SubActivityFormContainer;
